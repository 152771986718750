import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { mediaMin, mediaMax } from 'common/styledMixins';
import { breakpoint, zIndex } from 'common/styledVars';
import styled from 'styled-components';
import Layout from 'components/Layout';
import { HeaderMedium, MediumTextTransparent, BaseP, HeaderSmall } from 'components/typography';
import { LeftBox, WhiteWrapper, CaseBox } from 'components/pages/CaseStudies/Preview/styled';
import ArrowButton from 'components/Button/ArrowButton';
import { ImgGatsbyBackground } from 'components/wrappers';
import { DropLogo, ZulilyLogo } from 'components/icons';
import Helmet from "react-helmet";
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";

const LinkContainer = styled.div`
  height: 100%;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  @media ${mediaMin(breakpoint.desktop)} {
    max-width: 380px;
    min-width: 300px;
  }
`;

const CaseStudiesDiv = styled.div`
  @media only screen and (max-width: 981px) {
    & > div {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    & > div h1 {
      margin-bottom: 0;
    }

    & > div div {
      margin-top: 20px !important;
    }
  }
`;

const MainTitle = styled.h1`
    font-size: 28px;
    font-weight: 500;
    margin: 16px 0px 32px;

  @media ${mediaMin(breakpoint.desktop)} {
      font-size: 36px;
      margin: 42px 0 80px;
  }
`;
const Breadcrumbs = styled.p`
    padding: 25px 0px 25px 50px;
<<<<<<< HEAD
<<<<<<< HEAD
    width:100%;
    border-bottom: 1px solid #e3ebe9;
=======
    width:100%
>>>>>>> bf7b6d65e (27-09-2022)
=======
    width:100%;
    border-bottom: 1px solid #e3ebe9;
>>>>>>> 7184fbe7c (28-09-22-technical-changes)
`;

const CaseStudiesPreviewPage = () => {
  const [active, setActive] = useState('');
  const intl = useIntl()

  const womanData = useStaticQuery(graphql`
    query ImgWomanQuery {
      drop: file(relativePath: { eq: "drop-hover2.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      zulily: file(relativePath: { eq: "zulily-hover2.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Helmet>
        {/* <title>eCommerce Logistics Case Studies | Shipcoo</title> */}
        <title>{intl.formatMessage({ id: "meta-title-case-studies" })}</title>
        <link rel="alternate" href={intl.formatMessage({ id: "case-studies-link" })} hreflang={intl.formatMessage({ id: "language" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "case-studies-link-alt" })} hreflang={intl.formatMessage({ id: "language-alt" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "case-studies-link-alt2" })} hreflang={intl.formatMessage({ id: "language-alt2" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "case-studies-link-alt3" })} hreflang={intl.formatMessage({ id: "language-alt3" })} />
        <meta name="description" content="Read Shipcoo's ecommerce & logistics case studies. Find out why top consumer brands have picked us to handle their most crucial logistics operations." />
        <meta property="og:title" content={intl.formatMessage({ id: "meta-title-case-studies" })} />
        <meta property="og:description" content="Read Shipcoo's ecommerce & logistics case studies. Find out why top consumer brands have picked us to handle their most crucial logistics operations." />
        <meta name="twitter:title" content={intl.formatMessage({ id: "meta-title-case-studies" })} />
        <meta name="twitter:description" content="Read Shipcoo's ecommerce & logistics case studies. Find out why top consumer brands have picked us to handle their most crucial logistics operations." />
      </Helmet>
      <Breadcrumbs><Link to="/">Home</Link> / {intl.formatMessage({ id: "menu-case-studies" })}</Breadcrumbs>
      <WhiteWrapper>
        <CaseStudiesDiv>
          <LeftBox>
            <BaseP>{intl.formatMessage({ id: "case-studies-subheader" })}</BaseP>
            <MainTitle>{intl.formatMessage({ id: "case-studies-header" })}</MainTitle>
            <MediumTextTransparent>
              {intl.formatMessage({ id: "case-studies-description" })}
            </MediumTextTransparent>
          </LeftBox>
        </CaseStudiesDiv>
        <LinkContainer>
          <Link to="/case-studies/drop/">
            <CaseBox onMouseOver={() => setActive('01')} onMouseLeave={() => setActive('')}>
              <DropLogo />
              <BaseP>01</BaseP>
              <HeaderSmall>{intl.formatMessage({ id: "case-studies-drop-title" })}</HeaderSmall>
              <ArrowButton kind="white" active={active === '01'}>
                {intl.formatMessage({ id: "case-studies-read-button" })}
              </ArrowButton>
              <ImgGatsbyBackground fluid={womanData.drop.childImageSharp.fluid} alt="shipcoo drop shipping ecommerce platform optimize for drop's site" />
            </CaseBox>
          </Link>
        </LinkContainer>
        <LinkContainer>
          <Link to="/case-studies/zulily/">
            <CaseBox onMouseOver={() => setActive('02')} onMouseLeave={() => setActive('')}>
              <ZulilyLogo />
              <BaseP>02</BaseP>
              <HeaderSmall>{intl.formatMessage({ id: "case-studies-zulily-title" })}</HeaderSmall>
              <ArrowButton kind="white" active={active === '02'}>
                {intl.formatMessage({ id: "case-studies-read-button" })}
              </ArrowButton>
              <ImgGatsbyBackground fluid={womanData.zulily.childImageSharp.fluid} alt="shipcoo drop shipping ecommerce platform brand new logistics process for zulily" />
            </CaseBox>
          </Link>
        </LinkContainer>
      </WhiteWrapper>
    </Layout>
  );
};

export default CaseStudiesPreviewPage;
