import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';

import { mediaMin } from 'common/styledMixins';
import { breakpoint, color, zIndex } from 'common/styledVars';
import { HeaderMedium, MediumTextTransparent, BaseP, HeaderSmall } from 'components/typography';
import ArrowButton from 'components/Button/ArrowButton';
import { WidthWrapper, ImgGatsbyBackground } from 'components/wrappers';

export const Link = styled(GatsbyLink)`
  height: 100%;
  flex: 1;

  @media ${mediaMin(breakpoint.desktop)} {
    max-width: 380px;
    min-width: 300px;
  }
`;

export const LeftBox = styled.div`
  height: 100%;
  padding: 64px 0 91px 0;
  display: flex;
  flex-direction: column;
  flex: 1;

  ${HeaderMedium} {
    margin-top: 16px;
  }

  ${MediumTextTransparent} {
    margin-top: 64px;
  }

  @media ${mediaMin(breakpoint.desktop)} {
    max-width: 456px;
    padding: 71px 120px 116px 0;
    ${HeaderMedium} {
      margin-top: 42px;
    }
    ${MediumTextTransparent} {
      margin-top: auto;
    }
  }
`;

export const CaseBox = styled.div`
  position: relative;
  padding: 156px 50px 70px;
  height: 100%;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  flex: 1;
  cursor: pointer;

  ${BaseP} {
    color: ${color.white};
    margin-top: auto;
    z-index: ${zIndex.body + 1};
    transition: all 0.2s linear;
  }

  ${HeaderSmall} {
    margin: 21px 0 55px;
    z-index: ${zIndex.body + 1};
    transition: all 0.2s linear;
    color: ${color.white};
  }

  ${ArrowButton} {
    z-index: ${zIndex.body + 1};
  }

  ${ImgGatsbyBackground} {
    z-index: ${zIndex.body};
  }

  & > svg {
    position: absolute;
    top: 156px;
    left: 50px;
    transform: translate(0, -50%);
    z-index: ${zIndex.body + 1};
    path {
      fill: ${color.white};
    }
  }
`;

export const WhiteWrapper = styled(WidthWrapper)`
  min-height: 700px;
  margin-bottom: 64px;

  @media ${mediaMin(breakpoint.desktop)} {
    flex-direction: row;
    height: 60vh;
    max-height: 1320px;
    margin-bottom: 0;
  }
`;
